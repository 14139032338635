::-webkit-scrollbar {
    width: 7px;
  }
  
  ::-webkit-scrollbar-track {
    background: #F0FFF4;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #48BB78;
    border-radius: 12px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #68D391;
    border-radius: 12px;
  }